.tabs {
  display: flex;
  margin: 10px 0px;
}

.tab {
  flex: 1;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  margin: 0px 5px;
}

.tab:hover {
  background-color: blueviolet;
  color: #fff;
  border-radius: 10px;
}

.tab-select {
  background-color: rgb(87, 0, 168);
  color: #fff;
  border-radius: 10px;
}
